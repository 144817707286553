import React, { useState, useEffect } from "react";
import styles from "./CookieConsent.module.css";
import { initGA } from "../analytics"; // Import GA initialization

const CookieConsent = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const consent = localStorage.getItem("cookieConsent");
    if (!consent) {
      setIsVisible(true);
    } else if (consent === "accepted") {
      // Initialize Google Analytics if previously accepted
      initGA();
    }
  }, []);

  const handleAccept = () => {
    localStorage.setItem("cookieConsent", "accepted");
    setIsVisible(false);
    initGA(); // Initialize Google Analytics after acceptance
    // Dispatch event for user type tooltip
    window.dispatchEvent(new Event('cookieConsentGiven'));
  };

  const handleEssential = () => {
    localStorage.setItem("cookieConsent", "essential");
    setIsVisible(false);
    // Do not initialize Google Analytics
    // Dispatch event for user type tooltip
    window.dispatchEvent(new Event('cookieConsentGiven'));
  };

  const handleDecline = () => {
    localStorage.setItem("cookieConsent", "declined");
    setIsVisible(false);
    sessionStorage.clear(); // Clear any session data
    // Dispatch event for user type tooltip
    window.dispatchEvent(new Event('cookieConsentGiven'));
  };

  if (!isVisible) return null;

  return (
    <div className={styles.cookieConsent}>
      <div className={styles.cookieContent}>
        <h3>Cookie-Einstellungen</h3>
        <p>
          Willkommen bei der CodeStart Academy! Wir verwenden Cookies und temporäre Speicher, um dir die bestmögliche Nutzererfahrung zu bieten.
          Dies beinhaltet:
        </p>
        <ul className={styles.cookieList}>
          <li>Zwischenspeicherung deiner Quiz-Ergebnisse für eine persönliche Kursempfehlung</li>
          <li>Temporäre Daten für eine bessere Navigation auf unserer Website</li>
          <li>Google Analytics zur Verbesserung unserer Website (nur mit deiner Zustimmung)</li>
        </ul>
        <p className={styles.cookieNote}>
          Alle temporären Daten werden beim Schließen des Browsers automatisch gelöscht.
          Wenn du eine Kursanfrage sendest, werden deine Quiz-Ergebnisse nur mit deiner Zustimmung übermittelt.
          Mehr Informationen dazu findest du unter unserer <a href="/datenschutz">Datenschutzerklärung</a>.
        </p>
        <div className={styles.buttonContainer}>
          <button onClick={handleDecline} className={styles.declineButton}>
            Ablehnen
          </button>
          <button onClick={handleEssential} className={styles.essentialButton}>
            Nur Notwendige
          </button>
          <button onClick={handleAccept} className={styles.acceptButton}>
            Alle Akzeptieren
          </button>
        </div>
      </div>
    </div>
  );
};

export default CookieConsent;
