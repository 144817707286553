import React, { useState, useEffect, useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import coursesData from "../data/courses.json";

const NavigationBar = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isCoursesDropdownOpen, setIsCoursesDropdownOpen] = useState(false);
  const [showQuizHighlight, setShowQuizHighlight] = useState(false);  // New state

  const location = useLocation();

  const toggleMobileMenu = () => setIsMobileMenuOpen(!isMobileMenuOpen);

  const closeMobileMenu = () => {
    setIsMobileMenuOpen(false);
    setIsCoursesDropdownOpen(false);
  };

  const toggleCoursesDropdown = (e) => {
    e.preventDefault(); // Prevent any default behavior
    e.stopPropagation(); // Stop event bubbling
    setIsCoursesDropdownOpen(!isCoursesDropdownOpen);
  };

  const closeCoursesDropdown = () => setIsCoursesDropdownOpen(false);

  const handleCourseClick = () => {
    console.log("Course clicked"); // Debug log
    closeMobileMenu();
    closeCoursesDropdown();
  };

  // Close dropdowns when clicking outside
  const coursesRef = useRef();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (window.innerWidth > 768) {
        // Desktop view
        if (coursesRef.current && !coursesRef.current.contains(event.target)) {
          closeCoursesDropdown();
        }
      } else {
        // Mobile view
        const mobileMenu = document.getElementById("mobile-menu");
        const mobileMenuButton = document.querySelector(
          '[aria-controls="mobile-menu"]'
        );

        if (
          mobileMenu &&
          !mobileMenu.contains(event.target) &&
          !mobileMenuButton.contains(event.target)
        ) {
          closeMobileMenu(); // This will close both mobile menu and courses dropdown
        }
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // Close the mobile menu and dropdowns whenever the route changes
  useEffect(() => {
    setIsMobileMenuOpen(false);
    setIsCoursesDropdownOpen(false);
  }, [location]);

  // Listen for the typeIntroShown event
  useEffect(() => {
    const handleTypeIntroShown = () => {
      const timer = setTimeout(() => {
        setShowQuizHighlight(true);
      }, 500); // Small delay after first tooltip closes
      return () => clearTimeout(timer);
    };

    window.addEventListener('typeIntroShown', handleTypeIntroShown);
    return () => window.removeEventListener('typeIntroShown', handleTypeIntroShown);
  }, []);

  const dismissQuizHighlight = () => {
    setShowQuizHighlight(false);
    localStorage.setItem('quizIntroShown', 'true');
  };

  return (
    <nav className="bg-white shadow-md fixed w-full z-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between h-20 items-center">
          {/* Logo */}
          <div className="flex-shrink-0 items-center w-full md:w-auto flex justify-center md:justify-start">
            <Link to="/">
              <img className="h-16" src="/logos/CS_logo_block.svg" alt="Codestart" />
            </Link>
          </div>

          {/* Mobile menu button */}
          <div className="flex items-center md:hidden absolute right-4">
            <button
              onClick={toggleMobileMenu}
              type="button"
              className={`inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500 ${
                showQuizHighlight ? 'animate-pulse' : ''
              }`}
              aria-controls="mobile-menu"
              aria-expanded={isMobileMenuOpen}
            >
              <span className="sr-only">Open main menu</span>
              {/* Icon when menu is closed */}
              {!isMobileMenuOpen ? (
                <svg
                  className="block h-6 w-6"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M4 6h16M4 12h16M4 18h16"
                  />
                </svg>
              ) : (
                // Icon when menu is open
                <svg
                  className="block h-6 w-6"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              )}
            </button>
            {/* Mobile Tooltip */}
            {showQuizHighlight && (
              <div className="absolute right-full top-[170%] -translate-y-1/4 bg-custom_tertiary p-4 rounded-xl shadow-lg w-64 z-50 shadow-md">
                <p className="text-custom_white mb-2">
                  Wenn du dir nicht sicher bist, welcher Kurs der richtige ist, dann findest du hier im Menü unsere Kursübersicht oder das Einstufungsquiz!
                </p>
                <button 
                  onClick={dismissQuizHighlight}
                  className="text-sm text-custom_white font-extrabold hover:text-custom_neutral transition-colors duration-200 bg-custom_primary rounded-2xl py-2 px-4 shadow-md"
                >
                  Verstanden
                </button>
              </div>
            )}
          </div>

          {/* Navigation Links */}
          <div className="hidden md:flex flex-1 justify-center">
            <ul className="flex px-0 items-center my-0">
              
              {/* Courses Dropdown */}
              <li className="relative" ref={coursesRef}>
                <button
                  onClick={toggleCoursesDropdown}
                  type="button"
                  className="flex text-black text-xl font-medium px-3 py-2 rounded hover:shadow-md transition duration-200"
                >
                  Kurse
                  <svg
                    className={`ml-1 h-5 w-5 transform transition-transform ${
                      isCoursesDropdownOpen ? "rotate-180" : ""
                    }`}
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      fillRule="evenodd"
                      d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.14.976l-4.25 4.5a.75.75 0 01-1.14 0l-4.25-4.5a.75.75 0 01.02-1.06z"
                      clipRule="evenodd"
                    />
                  </svg>
                </button>

                {/* Dropdown Menu */}
                {isCoursesDropdownOpen && (
                  <div className="absolute left-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5">
                    <div
                      className="py-1"
                      role="menu"
                      aria-orientation="vertical"
                      aria-labelledby="options-menu"
                    >
                      <Link
                        to="/courses"
                        onClick={handleCourseClick}
                        className="no-underline block pl-4 pr-4 py-2 text-sm text-gray-700 hover:bg-custom_tertiary hover:text-white hover:border-custom_tertiary transition duration-200"
                        role="menuitem"
                      >
                        Kursübersicht
                      </Link>
                      <div className="border-t border-gray-100"></div>
                      {coursesData.courses.map((course) => (
                        <Link
                          key={course.id}
                          to={`/courses/${course.id}`}
                          onClick={handleCourseClick}
                          className="no-underline block pl-4 pr-4 py-2 text-sm text-gray-700 hover:bg-custom_tertiary hover:text-white hover:border-custom_tertiary transition duration-200"
                          role="menuitem"
                        >
                          {course.title}
                        </Link>
                      ))}
                      <Link
                        to="/faq"
                        onClick={handleCourseClick}
                        className="no-underline block pl-4 pr-4 py-2 text-sm text-gray-700 hover:bg-custom_tertiary hover:text-white hover:border-custom_tertiary transition duration-200"
                        role="menuitem"
                      >
                        Allgemeine Info
                      </Link>
                    </div>
                  </div>
                )}
              </li>

              <li>
                <Link
                  to="/preise"
                  className="text-black text-xl font-medium px-3 py-2 rounded hover:shadow-md transition duration-200 no-underline"
                >
                  Preise
                </Link>
              </li>

              <li>
                <Link
                  to="/info"
                  className="text-black text-xl font-medium px-3 py-2 rounded hover:shadow-md transition duration-200 no-underline"
                >
                  Über uns
                </Link>
              </li>
              <li>
                <Link
                  to="/contact"
                  className="text-black text-xl font-medium px-3 py-2 rounded hover:shadow-md transition duration-200 no-underline"
                >
                  Kontakt
                </Link>
              </li>

            </ul>
          </div>

          {/* Quiz Link */}
            <div className="hidden md:flex md:items-center flex-shrink-0 relative">
              <div className={`relative ${showQuizHighlight ? 'animate-pulse' : ''}`}>
                <Link
                  to="/quizcarousel"
                  className="ml-4 px-10 py-2 bg-custom_tertiary text-custom_white text-2xl font-semibold rounded-full shadow-md hover:text-black transition duration-200 no-underline"
                >
                  Quiz
                </Link>
                {/* Ring animation */}
                {showQuizHighlight && (
                  <div className="absolute left-1/4 inset-0 rounded-full animate-adapted_ping bg-custom_tertiary opacity-75 w-20"></div>
                )}
              </div>
              
              {/* Desktop Tooltip */}
              {showQuizHighlight && (
                <div className="absolute right-[90%] top-full mt-2 bg-custom_tertiary p-4 rounded-lg shadow-lg w-64 z-50">
                  <p className="text-custom_white mb-2">
                    Bist du unsicher, welcher Kurs zu dir passt? Mache hier unser Quiz und finde heraus, welcher Kurs am besten zu dir passt!
                  </p>
                  <button 
                    onClick={dismissQuizHighlight}
                    className="text-sm text-custom_white hover:text-custom_neutral transition-colors duration-200 bg-custom_primary rounded-2xl py-2 px-4 shadow-md"
                  >
                    Verstanden
                  </button>
                </div>
              )}
            </div>
        </div>
      </div>

      {/* Mobile Menu */}
      {isMobileMenuOpen && (
        <div className="md:hidden" id="mobile-menu">
          <div className="pt-2 pb-3 space-y-1">
            <Link
              to="/"
              className="block pl-3 pr-4 py-2 border-l-4 border-transparent text-base font-medium text-gray-700 no-underline"
            >
              Home
            </Link>

            {/* Programmierkurse Dropdown in Mobile */}
            <div className="space-y-1">
              <div
                onClick={(e) => {
                  e.stopPropagation();
                  console.log("Kurse clicked");
                  setIsCoursesDropdownOpen(!isCoursesDropdownOpen);
                }}
                className="w-full flex items-center justify-between pl-3 pr-4 py-2 border-l-4 border-transparent text-left text-base font-medium text-gray-700 hover:bg-custom_tertiary hover:text-white hover:border-custom_tertiary transition duration-200 cursor-pointer"
              >
                <span>Kurse</span>
                <svg
                  className={`ml-2 h-5 w-5 text-gray-400 transform transition-transform ${
                    isCoursesDropdownOpen ? "rotate-180" : ""
                  }`}
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path
                    fillRule="evenodd"
                    d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.14.976l-4.25 4.5a.75.75 0 01-1.14 0l-4.25-4.5a.75.75 0 01.02-1.06z"
                    clipRule="evenodd"
                  />
                </svg>
              </div>
              {isCoursesDropdownOpen && (
                <div
                  className="space-y-1"
                  onClick={(e) => e.stopPropagation()}
                >
                  <div
                    className="block pl-6 pr-4 py-2 border-l-4 border-transparent text-base font-medium text-gray-600 hover:bg-custom_tertiary hover:text-white hover:border-custom_tertiary transition duration-200 cursor-pointer"
                    onClick={(e) => {
                      e.stopPropagation();
                      console.log("Overview clicked");
                      setIsMobileMenuOpen(false);
                      setIsCoursesDropdownOpen(false);
                      window.location.href = "/courses";
                    }}
                  >
                    Kursübersicht
                  </div>
                  {coursesData.courses.map((course) => (
                    <div
                      key={course.id}
                      className="block pl-6 pr-4 py-2 border-l-4 border-transparent text-base font-medium text-gray-600 hover:bg-custom_tertiary hover:text-white hover:border-custom_tertiary transition duration-200 cursor-pointer"
                      onClick={(e) => {
                        e.stopPropagation();
                        console.log("Course clicked:", course.title);
                        setIsMobileMenuOpen(false);
                        setIsCoursesDropdownOpen(false);
                        window.location.href = `/courses/${course.id}`;
                      }}
                    >
                      {course.title}
                    </div>
                  ))}
                  <div
                    className="block pl-6 pr-4 py-2 border-l-4 border-transparent text-base font-medium text-gray-600 hover:bg-custom_tertiary hover:text-white hover:border-custom_tertiary transition duration-200 cursor-pointer"
                    onClick={(e) => {
                      e.stopPropagation();
                      console.log("Overview clicked");
                      setIsMobileMenuOpen(false);
                      setIsCoursesDropdownOpen(false);
                      window.location.href = "/faq";
                    }}
                  >
                    Allgemeine Info
                  </div>
                </div>
              )}
            </div>

            <Link
              to="/info"
              className="block pl-3 pr-4 py-2 border-l-4 border-transparent text-base font-medium text-gray-700 no-underline"
            >
              Über uns
            </Link>

            <Link
              to="/contact"
              className="block pl-3 pr-4 py-2 border-l-4 border-transparent text-base font-medium text-gray-700 no-underline"
            >
              Kontakt
            </Link>

            <Link
              to="/quizcarousel"
              className="block pl-3 pr-4 py-2 border-l-4 border-transparent text-base font-medium text-gray-700 no-underline"
            >
              Quiz
            </Link>
          </div>
        </div>
      )}
    </nav>
  );
};

export default NavigationBar;