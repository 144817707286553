import emailjs from "@emailjs/browser";

const sendEmail = (templateParams) => {
  // Initialize default values
  let score = -1;
  let summary = ["no quiz"];

  // Retrieve the stored score and answer summary from cookies
  const storedScore = sessionStorage.getItem("quizScore");
  const storedAnswers = sessionStorage.getItem("quizAnswers");

  // Replace default values with stored values if available
  if (storedScore) {
    score = parseInt(storedScore, 10);
  }
  if (storedAnswers) {
    //summary = storedAnswers.replace(/\n/g, '').trim();
    summary = storedAnswers;
  }

  const mergedParams = {
    ...templateParams,
    score: score, // Include score from sessionStorage if available
    detailed_message: summary // Detailed message from cookies if available
  };

  return emailjs
    .send("service_avdp053", "template_zxwm9x6", mergedParams, {
      publicKey: "-47DGh2xGEPDUJqFs",
    })
    .then((response) => {
      console.log("Email sent successfully!", response.status, response.text);
    })
    .catch((error) => {
      console.error("Failed to send email:", error);
    });
};

export default sendEmail;