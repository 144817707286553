import React, { useState, useEffect, useContext} from 'react';
import { UserContext } from '../context/UserContext';
import { useParams, Link } from 'react-router-dom';
import {
  MainContent,
  HeaderWrapper,
  ContentContainer,
  HeaderContainer,
  Title,
  Text
} from "./StyledComponents";
import courseData from '../data/courses.json';
import styles from './CourseById.module.css';

function CourseById() {
  
  const { id } = useParams();
  const [showPopup, setShowPopup] = useState(false);

  // Find the course by ID
  const course = courseData.courses.find(course => course.id === Number(id));

  useEffect(() => {
    // Reset the popup state when the id changes
    setShowPopup(false);
    
    // Trigger the popup after a short delay
    const timer = setTimeout(() => setShowPopup(true), 500);
    
    // Clear the timer on cleanup
    return () => clearTimeout(timer);
  }, [id]); // This effect runs every time the id changes

  const { isParent } = useContext(UserContext);
  if (isParent === undefined) {
    // Handle case when context is not available
    return <div>Loading...</div>; // Or any appropriate fallback UI
  }

  if (!course) {
    return (
      <MainContent>
        <ContentContainer>

            <p>Course not found!</p>

        </ContentContainer>
      </MainContent>
    );
  }

  

  // Construct the correct image path
  const imagePath = require(`../course_imgs/${course.img_src}`);


  return (
    <MainContent>
      <HeaderContainer>
        <HeaderWrapper>
          <Title>{course.title}</Title>
        </HeaderWrapper>
      </HeaderContainer>
      <ContentContainer className='flex flex-col justify-between'>

        {isParent ? (
          <>
            <div className={styles.Course_page}>
              <div className={styles.Course_info}>
                <div className={styles.Info_top}>
                  <div>
                    <Text className={styles.Info_top_cols}><strong>Geeignet für:</strong> </Text>
                    <Text className={styles.Info_top_cols}>{course.about.target_group}</Text>
                  </div>
                  <div>
                    <Text className={styles.Info_top_cols}><strong>Kursdauer:</strong></Text>
                    <Text className={styles.Info_top_cols}>{course.about.duration_in_months} Monate</Text>
                  </div>
                  <div>
                    <Text className={styles.Info_top_cols}><strong>Empfohlenes Alter:</strong> </Text>
                    <Text className={styles.Info_top_cols}>{course.about.recommended_age_min} - {course.about.recommended_age_max} Jahre</Text>
                  </div>
                </div>
                <Text className={styles.Info_text}>{course.full_description}</Text>
              </div>
              <div>
                <img className={styles.Course_image} src={imagePath} alt={`${course.title} logo`} />
                <div className={`${styles.Anfrage_button} ${showPopup ? styles.popup : ''}`}>
                  <Link to="/contact">Jetzt Anfragen</Link>
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className={styles.Course_page}>
              <div className={styles.Course_info}>
                <div className={styles.Info_top}>
                  <div>
                    <Text className={styles.Info_top_cols}><strong>Geeignet für:</strong> </Text>
                    <Text className={styles.Info_top_cols}>{course.about.target_group}</Text>
                  </div>
                  <div>
                    <Text className={styles.Info_top_cols}><strong>Kursdauer:</strong></Text>
                    <Text className={styles.Info_top_cols}>{course.about.duration_in_months} Monate</Text>
                  </div>
                  <div>
                    <Text className={styles.Info_top_cols}><strong>Empfohlenes Alter:</strong> </Text>
                    <Text className={styles.Info_top_cols}>{course.about.recommended_age_min} - {course.about.recommended_age_max} Jahre</Text>
                  </div>
                </div>
                <Text className={styles.Info_text}>{course.full_description_kind}</Text>
              </div>
              <div>
                <img className={styles.Course_image} src={imagePath} alt={`${course.title} logo`} />
                <div className={`${styles.Anfrage_button} ${showPopup ? styles.popup : ''}`}>
                  <Link to="/contact">Jetzt Anfragen</Link>
                </div>
              </div>
            </div>
              
          </>
        )
      }
          <div className={styles.bottom_buttons}>
            <div className={styles.backtocourse}>
              <Link to="/courses">Zurück zur Kursbersicht</Link>
            </div>
            <div className={styles.backtofaq}>
              <Link to="/faq">Allgemeine Informationen</Link>
            </div>
          </div>

      </ContentContainer>
    </MainContent>
  );
}

export default CourseById;