import React from 'react';
import styles from './Footer.module.css';
import {FaInstagram, FaTiktok, FaFacebook, FaLinkedin } from"react-icons/fa6"

function Footer() {
  const year = new Date().getFullYear();

  return (
    <footer className={styles.footer}>
      <div className={styles.container}>

        <ul className={styles.footer_links}>
          <li>
            <a href="https://www.instagram.com/codestart.online">
              <FaInstagram className={styles.SMicons}/>
            </a>
          </li>
          <li>
            <a href="https://www.tiktok.com/@codestart.online">
              <FaTiktok className={styles.SMicons}/>
            </a>
          </li>
          <li>
            <a href="https://www.facebook.com/codestart.online">
              <FaFacebook className={styles.SMicons}/>
            </a>
          </li>
          <li>
            <a href="https://www.linkedin.com/company/codestart-academy/">
              <FaLinkedin className={styles.SMicons}/>
            </a>
          </li>
        </ul>
        <ul className={styles.footer_links}>
          <li>© {year} CodeStart. All rights reserved. </li>
        </ul>
      </div>
    </footer>
  );
}

export default Footer;