import React from 'react';
import {
  MainContent,
  HeaderWrapper,
  ContentWrapper,
  ContentContainer,
  HeaderContainer,
  Subtitle,
  Text
} from "./StyledComponents";
import styles from './Home.module.css';
import { useContext } from 'react';
import { UserContext } from '../context/UserContext';

const Home = () => {
  const { isParent } = useContext(UserContext);

  if (isParent === undefined) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <MainContent>
        <HeaderContainer>
          <HeaderWrapper>
          </HeaderWrapper>
        </HeaderContainer>
        <ContentContainer>
          <ContentWrapper>
            {isParent ? (
              <>
                <div className={styles.Logo_wrapper}>
                  <Subtitle className={styles.Titel_top}>Willkommen bei der</Subtitle>
                  <img src="./logos/CS_logo_full.svg" alt="CS main logo" className={styles.Mainlogo} />
                  <Subtitle className={styles.Titel_bottom}>Academy!</Subtitle>
                </div>
                <Text className={styles.Welcome_text}>
                  Sieh dir jetzt unser <a href="/courses">Kursangebot</a> an,
                  finde den passenden Programmierkurs für dein Kind mit unserem <a href="/quizcarousel">Einstufungsquiz</a> oder
                  sende uns eine <a href="/contact">Anfrage</a> und starte das Codestart Abenteuer jetzt!
                </Text>
              </>
            ) : (
              <>
                <div className={styles.Logo_wrapper}>
                  <Subtitle className={styles.Titel_top}>Hey Kinder! Willkommen bei der</Subtitle>
                  <img src="./logos/CS_logo_full.svg" alt="CS main logo" className={styles.Mainlogo} />
                  <Subtitle className={styles.Titel_bottom}>Academy!</Subtitle>
                </div>
                <Text className={styles.Welcome_text}>
                  Schau dir unsere spannenden <a href="/courses">Programmierkurse</a> an,
                  teste dein Wissen im <a href="/quizcarousel">Quiz</a> oder
                  schreibe direkt eine <a href="/contact">Nachricht</a> an uns!
                  Hier findest du bestimmt etwas Tolles für dich!
                </Text>
                <Text className={styles.Welcome_text}>
                  Dein CodeStart Abenteuer beginnt jetzt! Bist du bereit?
                </Text>
              </>
            )}
          </ContentWrapper>
        </ContentContainer>
      </MainContent>
    </>
  );
};

export default Home;