import {
  MainContent,
  HeaderWrapper,
  ContentWrapper,
  ContentContainer,
  HeaderContainer,
  Title,
  Subtitle
} from "./StyledComponents";
import questions from '../data/quiz.json';
import styles from './QuizCarousel.module.css';
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

function QuizCarousel() {
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [answers, setAnswers] = useState(questions.map(() => []));
  const [score, setScore] = useState(null);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isReviewing, setIsReviewing] = useState(false);

  useEffect(() => {
    // Only try to load saved answers if consent was given
    // const consent = localStorage.getItem('cookieConsent');
  }, []);

  useEffect(() => {

  }, [answers]);

  const handleAnswerChange = (optionIndex) => {
    if (!isSubmitted) {
      const newAnswers = [...answers];
      const currentAnswers = newAnswers[currentQuestion];

      if (Array.isArray(currentAnswers) && currentAnswers.includes(optionIndex)) {
        newAnswers[currentQuestion] = currentAnswers.filter(index => index !== optionIndex);
      } else {
        newAnswers[currentQuestion] = [...currentAnswers, optionIndex].sort();
      }

      setAnswers(newAnswers);
    }
  };

  const goToPreviousQuestion = () => {
    setCurrentQuestion((prev) => Math.max(0, prev - 1));
  };

  const goToNextQuestion = () => {
    const currentScore = answers.reduce((score, answer, index) => {
      return score + (answer + 1 === questions[index].correctAnswer ? 1 : 0);
    }, 0);
    console.log("Current Score:", currentScore);
    setCurrentQuestion((prev) => Math.min(questions.length - 1, prev + 1));
  };

  const calculateScore = () => {
    return answers.reduce((total, answer, index) => {
      const correctAnswers = questions[index].correctAnswers;
      const isCorrect =
        answer.length === correctAnswers.length &&
        answer.every((value, index) => value === correctAnswers[index]);
      return total + (isCorrect ? 1 : 0);
    }, 0);
  };

  const saveScoreToLocalStorage = (score) => {
    const consent = localStorage.getItem('cookieConsent');
    if (consent === 'accepted' || consent === 'essential') {
      sessionStorage.setItem('quizScore', score.toString());
    }
  };

  const generatePlainText = (detailedAnswers) => {
    return detailedAnswers.map((question, index) => `
  QUESTION ${index + 1}:
  ${question.options.map(option => `
    - ${option.text} - ${option.isCorrect ? 'CORRECT' : ''} - ${option.isSelected ? 'SELECTED' : ''}`).join('')}
  `).join('\n');
  };

  const saveAnswersToLocalStorage = (answers) => {
    const consent = localStorage.getItem('cookieConsent');
    if (consent === 'accepted' || consent === 'essential') {
      const detailedAnswers = answers.map((answer, index) => {
        const correctAnswers = questions[index].correctAnswers;
        const options = questions[index].options;
        return {
          selected: Array.isArray(answer) ? answer : [],
          correct: correctAnswers,
          options: options.map((option, i) => ({
            text: option,
            isCorrect: correctAnswers.includes(i),
            isSelected: Array.isArray(answer) && answer.includes(i)
          }))
        };
      });
      //sessionStorage.setItem('quizAnswers', JSON.stringify(detailedAnswers));
      sessionStorage.setItem('quizAnswers', generatePlainText(detailedAnswers));
    }
  };
  const handleQuizSubmission = () => {
    const newScore = calculateScore();
    setScore(newScore);
    setIsSubmitted(true);
    saveScoreToLocalStorage(newScore);
    saveAnswersToLocalStorage(answers);
  };

  const getScoreMessage = (score) => {
    const percentage = (score / questions.length) * 100;

    if (percentage === 100) {
      return {
        title: "Ausgezeichnet! 🎉",
        message: "Du hast alle Fragen richtig beantwortet! Du bist definitiv bereit für den nächsten Schritt. Wenn du uns jetzt direkt eine Kursanfrage sendest, wird dein Ergebnis automatisch an uns weitergeleitet und wir können dir anhand deines Alters und Quizergebnisses den richtigen Kurs empfehlen."
      };
    } else if (percentage >= 80) {
      return {
        title: "Sehr gut! 👏",
        message: "Du hast ein ausgezeichnetes Verständnis gezeigt. Nur noch ein paar Kleinigkeiten zum Perfektionieren. Wenn du uns jetzt direkt eine Kursanfrage sendest, wird dein Ergebnis automatisch an uns weitergeleitet und wir können dir anhand deines Alters und Quizergebnisses den richtigen Kurs empfehlen."
      };
    } else if (percentage >= 60) {
      return {
        title: "Gut gemacht! 👍",
        message: "Du bist auf dem richtigen Weg. Mit etwas mehr Übung wirst du noch besser werden. Wenn du uns jetzt direkt eine Kursanfrage sendest, wird dein Ergebnis automatisch an uns weitergeleitet und wir können dir anhand deines Alters und Quizergebnisses den richtigen Kurs empfehlen."
      };
    } else if (percentage >= 40) {
      return {
        title: "Nicht schlecht! 💪",
        message: "Du hast grundlegendes Wissen gezeigt. Es gibt noch Raum für Verbesserung. Wenn du uns jetzt direkt eine Kursanfrage sendest, wird dein Ergebnis automatisch an uns weitergeleitet und wir können dir anhand deines Alters und Quizergebnisses den richtigen Kurs empfehlen."
      };
    } else {
      return {
        title: "Ein guter Start! 🌱",
        message: "Der erste Schritt ist getan. Lass uns gemeinsam daran arbeiten, dein Wissen zu vertiefen. Wenn du uns jetzt direkt eine Kursanfrage sendest, wird dein Ergebnis automatisch an uns weitergeleitet und wir können dir anhand deines Alters und Quizergebnisses den richtigen Kurs empfehlen."
      };
    }
  };

  const ResultsPage = () => {
    const { title, message } = getScoreMessage(score);
    const consent = localStorage.getItem('cookieConsent');

    return (
      <div className={styles.resultsContainer}>
        <h2 className={styles.resultsTitle}>{title}</h2>
        <div className={styles.scoreDisplay}>
          <div className={styles.scoreCircle}>
            <span className={styles.scoreNumber}>{score}</span>
            <span className={styles.scoreTotal}>/ {questions.length}</span>
          </div>
        </div>
        <p className={styles.resultMessage}>
          {message}
          {consent !== 'accepted' && (
            <span className={styles.consentNote}>
              <br /><br />
              Hinweis: Da du keine Cookies akzeptiert hast, können deine Ergebnisse
              nicht gespeichert werden. Wenn du möchtest, dass deine Ergebnisse mit
              deiner Anfrage übermittelt werden, aktualisiere bitte die Seite und
              stimme der Cookie-Nutzung zu.
            </span>
          )}
        </p>
        <div className={styles.actionButtons}>
          <button
            onClick={() => setIsReviewing(true)}
            className={styles.reviewButton}
          >
            Antworten überprüfen
          </button>
          <Link to="/contact" className={styles.contactButton}>
            Kursanfrage senden
          </Link>
        </div>
      </div>
    );
  };

  const ReviewContent = () => {
    const question = questions[currentQuestion];

    return (
      <>
        <div className={styles.questionContainer}>
          <h3 className={styles.questionText}>{question.question}</h3>
          <div className={styles.optionsContainer}>
            {question.options.map((option, optionIndex) => (
              <label
                key={optionIndex}
                className={`${styles.optionLabel} ${question.correctAnswers.includes(optionIndex)
                  ? styles.correctBackground
                  : answers[currentQuestion].includes(optionIndex)
                    ? styles.incorrectBackground
                    : ''
                  }`}
              >
                <input
                  type="checkbox"
                  name={`question-${currentQuestion}`}
                  value={optionIndex}
                  checked={answers[currentQuestion].includes(optionIndex)}
                  disabled={true}
                  className={styles.checkboxInput}
                />
                <span className={styles.optionText}>{option}</span>
                {question.correctAnswers.includes(optionIndex) && (
                  <span className={`${styles.feedbackIcon} ${styles.correct}`}>✓</span>
                )}
                {answers[currentQuestion].includes(optionIndex) &&
                  !question.correctAnswers.includes(optionIndex) && (
                    <span className={`${styles.feedbackIcon} ${styles.incorrect}`}>✗</span>
                  )}
              </label>
            ))}
          </div>

          {question.explanation && (
            <div className={styles.explanationBox}>
              <h4 className={styles.explanationTitle}>Erklärung:</h4>
              <p className={styles.explanationText}>{question.explanation}</p>
            </div>
          )}
        </div>

        <div className={styles.navigationContainer}>
          <div className={styles.navigationButtons}>
            {currentQuestion > 0 && (
              <button onClick={goToPreviousQuestion} className={styles.navButton}>
                Zurück
              </button>
            )}
            <div className={styles.rightAlignedButtons}>
              {currentQuestion < questions.length - 1 ? (
                <button onClick={goToNextQuestion} className={styles.navButton}>
                  Weiter
                </button>
              ) : (
                <button
                  onClick={() => setIsReviewing(false)}
                  className={styles.returnButton}
                >
                  Zurück zur Übersicht
                </button>
              )}
            </div>
          </div>
          <Link to="/contact" className={styles.contactButton}>
            Kursanfrage senden
          </Link>
        </div>

        <div className={styles.progressIndicator}>
          {currentQuestion + 1} / {questions.length}
        </div>
      </>
    );
  };

  const QuizContent = () => {
    const question = questions[currentQuestion];

    return (
      <>
        <div className={styles.questionContainer}>
          <Subtitle className={styles.questionText}>{question.question}</Subtitle>
          <div className={styles.optionsContainer}>
            {question.options.map((option, optionIndex) => (
              <label
                key={optionIndex}
                className={styles.optionLabel}
              >
                <input
                  type="checkbox"
                  name={`question-${currentQuestion}`}
                  value={optionIndex}
                  checked={answers[currentQuestion].includes(optionIndex)}
                  onChange={() => handleAnswerChange(optionIndex)}
                  className={styles.checkboxInput}
                />
                <span className={styles.optionText}>{option}</span>
              </label>
            ))}
          </div>
        </div>
        <div className={styles.navigationContainer}>
          <div className={styles.navigationButtons}>
            {currentQuestion > 0 && (
              <button onClick={goToPreviousQuestion} className={styles.navButton}>
                Zurück
              </button>
            )}
            <div className={styles.rightAlignedButtons}>
              {currentQuestion < questions.length - 1 ? (
                <button onClick={goToNextQuestion} className={styles.navButton}>
                  Weiter
                </button>
              ) : (
                <button onClick={handleQuizSubmission} className={styles.submitButton}>
                  Abgeben
                </button>
              )}
            </div>
          </div>
        </div>
        <div className={styles.progressIndicator}>
          {currentQuestion + 1} / {questions.length}
        </div>
      </>
    );
  };

  return (
    <MainContent>
      <HeaderContainer>
        <HeaderWrapper>
          <Title>Quiz</Title>
        </HeaderWrapper>
      </HeaderContainer>
      <ContentContainer>
        <ContentWrapper>
          <div className={styles.quizContainer}>
            {isSubmitted
              ? (isReviewing ? <ReviewContent /> : <ResultsPage />)
              : <QuizContent />
            }
          </div>
        </ContentWrapper>
      </ContentContainer>
    </MainContent>
  );
}

export default QuizCarousel;