import React from "react";
import { 
  MainContent, 
  HeaderWrapper, 
  ContentWrapper, 
  ContentContainer, 
  HeaderContainer, 
  Title,
  Text,
  Divide } 
  from "./StyledComponents";
import Team from "./Team";
import OurVision from "./OurVision";

const Info = () => (
  <MainContent> 
      <HeaderContainer>
        <HeaderWrapper>
          <Title>Informationen</Title>
        </HeaderWrapper>
      </HeaderContainer>
      <ContentContainer >
        <ContentWrapper>
          <OurVision/>
          <Divide/>
          <Team/>
          <Divide/>
          <Text className="text-center"> Noch weitere Fragen? Hier geht es zu den <a href="/faq" className="text-custom_tertiary">FAQs</a>!</Text>
        </ContentWrapper>
      </ContentContainer>
  </MainContent>
);

export default Info;
