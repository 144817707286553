import ReactGA from "react-ga4";

const GA_TRACKING_ID = "G-EMR209SBER"; // Replace with your Measurement ID
const ADS_ID = "AW-16791111164";

// Initialize Google Analytics if consent is given
export const initGA = () => {
  const consent = localStorage.getItem("cookieConsent");
  if (consent === "accepted") {
    ReactGA.initialize(GA_TRACKING_ID);
    
    // Initialize Google Ads tag
    window.dataLayer = window.dataLayer || [];
    function gtag(){window.dataLayer.push(arguments);}
    gtag('js', new Date());
    gtag('config', ADS_ID);
    
    // Load the gtag.js script
    const script = document.createElement('script');
    script.async = true;
    script.src = `https://www.googletagmanager.com/gtag/js?id=${ADS_ID}`;
    document.head.appendChild(script);
  }
};

// Track a page view if GA is initialized
export const trackPageView = (path) => {
  const consent = localStorage.getItem("cookieConsent");
  if (consent === "accepted") {
    ReactGA.send({ hitType: "pageview", page: path, title: document.title });
  }
};
