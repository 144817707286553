import { useEffect, useCallback } from 'react';
import { useLocation, matchPath } from 'react-router-dom';

const BreadcrumbStructuredData = () => {
  const location = useLocation();

  const getBreadcrumbName = useCallback((segment) => {
    const nameMap = {
      'courses': 'Kurse',
      'team': 'Team',
      'info': 'Info',
      'quiz': 'Kursberater',
      'quizCarousel': 'Kursberater',
      'contact': 'Kontakt',
      'datenschutz': 'Datenschutz',
      'impressum': 'Impressum',
      'faq': 'FAQ',
      'jobs': 'Jobs',
      'promo': 'Aktionen'
    };
    return nameMap[segment] || segment;
  }, []);

  const getCourseNameById = useCallback((courseId) => {
    const courseMap = {
      '1': 'CS Grundlagen',
      '2': 'CS Webdesign 1',
      '3': 'CS Spieldesign 1',
      '4': 'CS Webdesign 2',
      '5': 'CS Appdesign',
      '6': 'CS Python',
      '7': 'CS Spieldesign 2'
    };
    return courseMap[courseId] || courseId;
  }, []);

  const generateBreadcrumbData = useCallback((pathname) => {
    const breadcrumbList = {
      '@context': 'https://schema.org',
      '@type': 'BreadcrumbList',
      'itemListElement': [
        {
          '@type': 'ListItem',
          'position': 1,
          'name': 'Home',
          'item': 'https://codestart.online'
        }
      ]
    };

    if (pathname === '/') {
      return breadcrumbList;
    }

    const courseMatch = matchPath('/courses/:id', pathname);
    if (courseMatch) {
      const courseId = courseMatch.params.id;
      breadcrumbList.itemListElement.push({
        '@type': 'ListItem',
        'position': 2,
        'name': 'Kurse',
        'item': 'https://codestart.online/courses'
      });
      breadcrumbList.itemListElement.push({
        '@type': 'ListItem',
        'position': 3,
        'name': getCourseNameById(courseId),
        'item': `https://codestart.online/courses/${courseId}`
      });
      return breadcrumbList;
    }

    const segment = pathname.split('/')[1];
    if (segment) {
      breadcrumbList.itemListElement.push({
        '@type': 'ListItem',
        'position': 2,
        'name': getBreadcrumbName(segment),
        'item': `https://codestart.online/${segment}`
      });
    }

    return breadcrumbList;
  }, [getBreadcrumbName, getCourseNameById]);

  useEffect(() => {
    const existingScript = document.getElementById('breadcrumbStructuredData');
    if (existingScript) {
      existingScript.remove();
    }

    const data = generateBreadcrumbData(location.pathname);

    const script = document.createElement('script');
    script.id = 'breadcrumbStructuredData';
    script.type = 'application/ld+json';
    script.innerHTML = JSON.stringify(data);
    document.head.appendChild(script);

    return () => {
      const scriptToRemove = document.getElementById('breadcrumbStructuredData');
      if (scriptToRemove) {
        scriptToRemove.remove();
      }
    };
  }, [location.pathname, generateBreadcrumbData]);

  return null;
};

export default BreadcrumbStructuredData;