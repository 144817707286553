import React, { useState, useEffect } from 'react';


const PromoPopup = () => {
  const [showMainPopup, setShowMainPopup] = useState(false);
  const [showReminder, setShowReminder] = useState(false);

  // Handle main popup display
  useEffect(() => {
    const typeIntroShown = localStorage.getItem('typeIntroShown');
    const promoShown = sessionStorage.getItem('promoShown');

    if (!promoShown) {
      if (typeIntroShown) {
        const timer = setTimeout(() => {
          setShowMainPopup(true);
        }, 2000);
        return () => clearTimeout(timer);
      } else {
        const handleTypeIntroShown = () => {
          const timer = setTimeout(() => {
            setShowMainPopup(true);
          }, 5000);
          return () => clearTimeout(timer);
        };

        window.addEventListener('typeIntroShown', handleTypeIntroShown);
        return () => {
          window.removeEventListener('typeIntroShown', handleTypeIntroShown);
        };
      }
    }
  }, []);

  // Handle reminder display
  useEffect(() => {
    const promoShown = sessionStorage.getItem('promoShown');
    if (promoShown) {
      setShowReminder(true);
    }
  }, []);

  const handleClose = () => {
    setShowMainPopup(false);
    sessionStorage.setItem('promoShown', 'true');
    setShowReminder(true);
  };

  return (
    <>
      {/* Main Popup */}
      {showMainPopup && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white rounded-2xl shadow-xl border-8 border-custom_primary p-6 m-4 max-w-sm w-full relative animate-popup">
            {/* Close button */}
            <button
              onClick={handleClose}
              className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
              aria-label="Close"
            >
              <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>

            {/* Promotion Content */}
            <div className="text-center">
              <h2 className="text-2xl font-bold text-custom_tertiary mb-4">
                iPad-Gewinnspiel 2025!
              </h2>
              
              <p className="text-gray-700 mb-4">
                Melde dein Kind jetzt an und sichere dir die Chance auf ein iPad!
                Die ersten 25 Anmeldungen nehmen automatisch teil.
              </p>

              <div className="space-y-4">
                <a
                  href="/promo"
                  onClick={handleClose}
                  className="block w-full bg-custom_tertiary text-white rounded-3xl shadow-md px-4 py-2 font-semibold hover:bg-green-700 transition duration-200"
                >
                  Mehr erfahren
                </a>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Reminder */}
      {showReminder && (
        <>
          {/* Desktop version - bottom left corner */}
          <div className="hidden md:block fixed bottom-12 left-4 z-50">
            <a
              href="/promo"
              className="bg-custom_primary text-white text-md rounded-3xl shadow-md px-3 py-2 flex items-center gap-2 no-underline hover:bg-green-700 transition duration-200 animate-popup"
            >
              <img
                src="promo_imgs/gift_icon.png"
                alt="Gift icon"
                className="w-5 h-5 object-contain"
              />
              <span>iPad Verlosung!</span>
            </a>
          </div>

          {/* Mobile version - bar above footer */}
          <div className="md:hidden fixed bottom-12 left-0 right-0 z-50">
            <a
              href="/promo"
              className="w-full bg-custom_primary text-white text-sm py-0.5 px-4 flex items-center justify-center gap-2 no-underline hover:bg-green-700 transition duration-200 animate-popup"
            >
              <img
                src="promo_imgs/gift_icon.png"
                alt="Gift icon"
                className="w-4 h-4 object-contain"
              />
              <span>iPad Verlosung!</span>
            </a>
          </div>
        </>
      )}
    </>
  );
};

export default PromoPopup;