import React from 'react';
import {
  MainContent,
  HeaderWrapper,
  ContentContainer,
  HeaderContainer,
  Title,
  Subtitle
} from "./StyledComponents";

function Promotions() {
  return (
    <MainContent>
      <HeaderContainer>
        <HeaderWrapper>
          <Title></Title>
        </HeaderWrapper>
      </HeaderContainer>
      <ContentContainer>
        {/* Haupterklärung */}
        <div className="max-w-3xl mx-auto mb-8">
            <Subtitle className='my-8'>iPad-Gewinnspiel für neue CodeKids 2025!</Subtitle>
          <p className="text-lg text-center mb-8">
            Die ersten 25 CodeKids, die sich im Jahr 2025 für einen Kurs bei der
            CodeStart Academy anmelden, haben die Chance, ein cooles CodeStart iPad
            im Wert von 399€ zu gewinnen.
          </p>
          
          {/* iPad Abbildung */}
          <div className="w-full max-w-md mx-auto mb-12">
            <img
              src="promo_imgs/ipad.jpg"
              alt="Codestart iPad"
              className="w-full rounded-lg"
            />
          </div>
        </div>

        {/* Teilnahmebedingungen */}
        <div className="bg-gray-50 p-6 rounded-lg shadow-md mb-8 max-w-3xl mx-auto">
          <h3 className="text-2xl font-bold mb-4">Bedingungen zu Teilnahme</h3>
          <ul className="space-y-3">
            <li className="flex items-center">
              <span className="text-custom_tertiary text-4xl leading-none mr-2">•</span>
              Kursanmeldungen erfolgen via Email an info@codestart.online oder über das Kontaktformular der Website.
            </li>
            <li className="flex items-center">
              <span className="text-custom_tertiary text-4xl mr-2">•</span>
              Nur eine Teilnahme an der Verlosung pro Anmeldung.
            </li>
            <li className="flex items-center">
              <span className="text-custom_tertiary text-4xl mr-2">•</span>
              Nur Kinder zwischen 6 und 18 Jahren können angemeldet werden.
            </li>
            <li className="flex items-center">
              <span className="text-custom_tertiary text-4xl mr-2">•</span>
              Teilnahme aus allen EU Ländern möglich, solange die Kinder an einem Programmierkurs auf Deutsch teilnehmen können.
            </li>
            <li className="flex items-center">
              <span className="text-custom_tertiary text-4xl mr-2">•</span>
              Die Teilnahme an der Verlosung kann nur erfolgen, wenn aus der Anmeldung auch ein Kurs resultiert.
            </li>
            <li className="flex items-center">
              <span className="text-custom_tertiary text-4xl mr-2">•</span>
              Die Anmeldung muss spätestens bis zum 28.02.2025 um 23:59 Uhr erfolgen.
            </li>
          </ul>
        </div>

        {/* Rechtliche Hinweise */}
        <div className="bg-gray-100 p-6 rounded-lg shadow-md mb-8 max-w-3xl mx-auto">
          <h3 className="text-2xl font-bold mb-4">Rechtliche Hinweise</h3>
          
          {/* Allgemeine Gewinnspielregeln */}
          <div className="mb-6">
            <h4 className="font-semibold mb-2">Durchführung des Gewinnspiels</h4>
            <p className="mb-4">
              Der/die Gewinner:in wird per Zufallsprinzip ermittelt und anschließend von uns via Email kontaktiert. 
              Wir behalten uns vor, das Gewinnspiel ohne Gewinner:in abzuschließen, falls die Mindestanzahl an 
              Anmeldungen bis zum Ende der Frist nicht erreicht wurde. Der Rechtsweg ist ausgeschlossen. 
              Eine Barauszahlung des Gewinns ist nicht möglich.
            </p>
          </div>

          {/* Teilnahme Minderjähriger */}
          <div className="mb-6">
            <h4 className="font-semibold mb-2">Anmeldung und Einwilligung</h4>
            <p className="mb-4">
              Die Anmeldung zum Programmierkurs und die Teilnahme am Gewinnspiel muss durch einen 
              Erziehungsberechtigten erfolgen. Mit der Anmeldung erklären die Erziehungsberechtigten 
              ihr Einverständnis zur Teilnahme ihres Kindes am Gewinnspiel. Im Gewinnfall wird der 
              Gewinn an die Erziehungsberechtigten übergeben.
            </p>
          </div>

          {/* Datenschutz */}
          <div>
            <h4 className="font-semibold mb-2">Datenschutz</h4>
            <p className="mb-2">
              Die im Rahmen der Anmeldung erhobenen personenbezogenen Daten werden ausschließlich zur 
              Durchführung des Gewinnspiels und der Kursanmeldung verwendet. Rechtsgrundlage ist Art. 6 
              Abs. 1 lit. b DSGVO (Vertragserfüllung) sowie Ihre Einwilligung (Art. 6 Abs. 1 lit. a DSGVO).
            </p>
            <p>
              Die Daten werden nur für die Dauer des Gewinnspiels sowie anschließend für die gesetzlich 
              vorgeschriebene Aufbewahrungsfrist gespeichert. Sie haben das Recht auf Auskunft, 
              Berichtigung, Löschung und Einschränkung der Verarbeitung der Daten nach den gesetzlichen 
              Vorgaben. Weitere Informationen finden Sie in unserer Datenschutzerklärung.
            </p>
          </div>
        </div>


        {/* Kontakt */}
        <div className="text-center max-w-3xl mx-auto mb-8">
          <h3 className="text-2xl font-bold mb-4">Kontakt</h3>
          <p>
            Anmeldung und Fragen per Email an:{' '}
            <a href="mailto:info@codestart.online" className="text-custom_tertiary hover:underline">
              info@codestart.online
            </a>
          </p>
          <p className="mt-2">
            oder über unser{' '}
            <a href="/contact" className="text-custom_tertiary hover:underline">
              Kontaktformular
            </a>
          </p>
        </div>
      </ContentContainer>
    </MainContent>
  );
}

export default Promotions;