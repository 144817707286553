import React from 'react';
import { Link } from 'react-router-dom';
import { 
    MainContent, 
    HeaderWrapper, 
    ContentWrapper, 
    ContentContainer, 
    HeaderContainer, 
    Title,
    Text} 
from "./StyledComponents";
import priceData from '../data/prices.json';

const PriceCard = ({ model }) => {
    return (
      <div className="flex flex-col p-6 bg-custom_white rounded-xl border-4 border-custom_primary border-opacity-30  h-full">
        {/* Card Header - Shows the package title and minimum duration */}
        <div className="mb-4">
          <h3 className="text-xl font-bold bg-custom_primary shadow-md text-custom_white text-center rounded-3xl py-2 mb-2">{model.title}</h3>
          
        </div>
        
        {/* Price Information - Displays cost per unit and weekly schedule */}
        <div className="mb-6 text-center">
        <p className="text-3xl font-bold text-gray-800 mb-2">
                {model.price_per_UE === "kostenlos" ? "Kostenlos" : `${model.price_per_UE}`}
            </p>
            <p className='text-custom_neutral font-light text-sm'> pro Unterrichtseinheit</p>
        </div>
        
        {/* Payment Details - Shows the payment schedule at the bottom of the card */}
        <div className="mt-auto">
            <ul className='list-disc'>
                <li className="text-custom_neutral font-medium my-2">Mindestlaufzeit: {model.minimum_duration}</li>
                <li className="text-custom_neutral font-medium my-2">{model.weekly}</li>
                <li className="text-custom_neutral font-medium my-2">{model.payment}</li>
            </ul>
        </div>
      </div>
    );
  };
  
  // Main Preise component
  const Preise = () => {
    return (
      <MainContent>
        <HeaderContainer>
          <HeaderWrapper>
            <Title>Mitgliedschaften</Title>
          </HeaderWrapper>
        </HeaderContainer>
        <ContentContainer>
        <ContentWrapper>
          <Text className="mb-8">
            Die Preise für unsere Programmierkurse richten sich nach dem jeweiligen Mitgliedspaket, dass du für dein Kind wählst.
          </Text>
          <div className="space-y-6">
            {/* Top row container: three cards */}
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
              {priceData.price_models.slice(0, 3).map((model) => (
                <div key={model.id}>
                  <PriceCard model={model} />
                </div>
              ))}
            </div>
            
            {/* Bottom row container: two centered cards */}
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6 lg:w-2/3 lg:mx-auto">
              {priceData.price_models.slice(3).map((model) => (
                <div key={model.id}>
                  <PriceCard model={model} />
                </div>
              ))}
            </div>
          </div>
              

            <div className='w-fit text-xl font-bold bg-custom_tertiary shadow-md text-center rounded-3xl py-2 px-4 my-10 mx-auto'>
                <Link to="/courses" className='no-underline text-custom_white hover:text-custom_neutral'>Jetzt Kurs aussuchen und anfragen</Link>
            </div>
        </ContentWrapper>
      </ContentContainer>
    </MainContent>
  );
};

export default Preise;