import React from 'react';
import { Link } from 'react-router-dom';
import TestimonialCarousel from './TestimonialCarousel';
import testimonials from '../data/testimonials.json';

const PageBottom = () => {
  return (
    <div className="w-full pt-8 pb-16 px-4 bg-custom_white bg-opacity-95 mt-1">
      <TestimonialCarousel testimonials={testimonials} />
      <div className="w-1/2 md:w-96 mx-auto grid grid-cols-1 md:grid-cols-3 gap-8">
        {/* CodeStart Column */}
        <div className="flex flex-col mx-auto">
          <h3 className="text-lg font-bold mb-2">CodeStart</h3>
          <ul className="space-y-1 text-left w-full pl-1">
            <li>
              <Link 
                to="/info" 
                className="text-custom_neutral hover:text-gray-900 no-underline"
              >
                Über uns
              </Link>
            </li>
            <li>
              <Link 
                to="/courses" 
                className="text-gray-600 hover:text-gray-900 no-underline"
              >
                Kursangebot
              </Link>
            </li>
            <li>
              <Link 
                to="/faq" 
                className="text-gray-600 hover:text-gray-900 no-underline"
              >
                FAQs
              </Link>
            </li>
            <li>
              <Link 
                to="/contact" 
                className="text-gray-600 hover:text-gray-900 no-underline"
              >
                Kontakt
              </Link>
            </li>
            <li>
              <Link 
                to="/jobs" 
                className="text-gray-600 hover:text-gray-900 no-underline"
              >
                Karriere
              </Link>
            </li>
          </ul>
        </div>

        {/* Legal Column */}
        <div className="flex flex-col mx-auto">
          <h3 className="text-lg font-bold mb-2">Rechtliches</h3>
          <ul className="space-y-1 text-left w-full pl-1">
            <li>
              <Link 
                to="/datenschutz" 
                className="text-gray-600 hover:text-gray-900 no-underline"
              >
                Datenschutz
              </Link>
            </li>
            <li>
              <Link 
                to="/impressum" 
                className="text-gray-600 hover:text-gray-900 no-underline"
              >
                Impressum
              </Link>
            </li>
          </ul>
        </div>

        {/* Social Media Column */}
        <div className="flex flex-col mx-auto">
          <h3 className="text-lg font-bold mb-2 whitespace-nowrap">Social Media</h3>
          <ul className="space-y-1 text-left w-full pl-1">
            <li>
              <a 
                href="https://www.instagram.com/codestart.online" 
                className="text-gray-600 hover:text-gray-900 no-underline"
              >
                Instagram
              </a>
            </li>
            <li>
              <a 
                href="https://www.tiktok.com/@codestart.online" 
                className="text-gray-600 hover:text-gray-900 no-underline"
              >
                TikTok
              </a>
            </li>
            <li>
              <a 
                href="https://www.facebook.com/codestart.online" 
                className="text-gray-600 hover:text-gray-900 no-underline"
              >
                Facebook
              </a>
            </li>
            <li>
              <a 
                href="https://www.linkedin.com/company/codestart-academy/" 
                className="text-gray-600 hover:text-gray-900 no-underline"
              >
                LinkedIn
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default PageBottom;