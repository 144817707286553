import styled from 'styled-components';

export const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  @media (min-width: 768px) {
  height: 100vh;
}
`;

export const MainContentWrapper = styled.div`
background-image: linear-gradient(to bottom right, white, rgb(255, 198, 54));
padding: 0;
margin: 0;
`

export const MainContent = styled.main`
  flex: 1;
  display: flex;
  flex-direction: column;

  padding: 40px 0px 10px 0px;
  min-height: 88vh;
`;

export const HeaderContainer = styled.div`
  padding: 20px;
  width: 80%;
  max-width: 1200px;
  margin: auto;
  box-sizing: border-box;

`;

export const ContentContainer = styled.div`
  flex: 1;
  width: 80%;

  max-width: 1200px;
  margin: auto;
  padding: 30px 30px 0px;
  background-color: rgba(255, 255, 255, 0.95);
  border-radius: 20px;
  box-shadow: 2px 2px 4px rgba(0,0,0,.1),
            -2px -2px 4px rgba(0,0,0,.1);
  @media (max-width: 768px) {
    border-radius: 20px;
    box-shadow: none;
    width: 90%
    padding: 10px; // Smaller padding on smaller screens
  }
`;

export const ContentWrapper = styled.div`

  @media (max-width: 768px) {

  }
`;

export const HeaderWrapper = styled.div`
  padding: 0;
  margin: 0;
  width: 100%;
`;

export const Title = styled.h1`
  color: rgb(90, 90, 90);
`;

export const Subtitle = styled.h1`
  text-align: Center;
  color: rgb(90, 90, 90);
`;

export const Subcontent = styled.div`
  margin: 0;
`;

export const Divide = styled.hr`
  border: 2px solid black;
  margin-bottom: 30px;
  @media (min-width: 768px) {
  margin-bottom: 100px;
  }
  
`;

export const Text = styled.p`
  color: rgb(90, 90, 90);
  font-size: large;
  letter-spacing: 1px;
@media (max-width: 768px) {
  letter-spacing: 1px;
  }
@media (min-width: 768px) {
  letter-spacing: 2px;

  }
@media (min-width: 1198px) {
    font-size: 21px;
  }

`;


export const List = styled.ul`
  list-style-type: none;
  padding: 0;
`;

export const ListItem = styled.li`
  padding: 10px;
  border-bottom: 1px solid #ddd;
  &:hover {
    background-color: #e6ffec;
  }
`;

export const EmailLink = styled.a`
  color: rgb(60, 60, 60);
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;
