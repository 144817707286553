import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

// First, define the component
const ScrollToTop = () => {
  const location = useLocation();
  
  useEffect(() => {
    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: 'auto'
      });
    };
    
    // Add a small delay to ensure the new page content is rendered
    const timeoutId = setTimeout(scrollToTop, 100);
    
    // Cleanup the timeout if the component unmounts
    return () => clearTimeout(timeoutId);
  }, [location.pathname]);
  
  return null;
};

// Then, add this line at the end of the file to export the component
export default ScrollToTop;