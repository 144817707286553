import React, { useState, useContext, useEffect, useRef } from "react";
import { UserContext } from "../context/UserContext";
import kidIcon from "../icons/kid_icon.png";
import parentIcon from "../icons/parent_icon.png";
import "./UserTypeButton.css";

const UserTypeButton = () => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [showHighlight, setShowHighlight] = useState(false);
  const { isParent, setIsParent } = useContext(UserContext);
  const dropdownRef = useRef();

  useEffect(() => {
    // Check if cookie consent exists and intro hasn't been shown
    const consent = localStorage.getItem('cookieConsent');
    const introShown = localStorage.getItem('typeIntroShown');
    
    if (consent && !introShown) {
      // Add a delay before showing the animation
      const timer = setTimeout(() => {
        setShowHighlight(true);
      }, 1500); // 1.5 second delay

      return () => clearTimeout(timer);
    }
  }, []); // Empty dependency array means this runs once on mount

  const toggleDropdown = () => setIsDropdownOpen(!isDropdownOpen);
  
  const closeDropdown = () => setIsDropdownOpen(false);
  
  const handleUserSelect = (type) => {
    setIsParent(type === "parent");
    closeDropdown();
  };

  const dismissHighlight = () => {
    setShowHighlight(false);
    localStorage.setItem('typeIntroShown', 'true');
    // Dispatch event for quiz highlight
    window.dispatchEvent(new Event('typeIntroShown'));
  };

  // Close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        closeDropdown();
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const handleCookieConsent = () => {
      // Add a small delay before showing the tooltip
      const timer = setTimeout(() => {
        const introShown = localStorage.getItem('typeIntroShown');
        if (!introShown) {
          setShowHighlight(true);
        }
      }, 2500);

      return () => clearTimeout(timer);
    };

    window.addEventListener('cookieConsentGiven', handleCookieConsent);
    return () => window.removeEventListener('cookieConsentGiven', handleCookieConsent);
  }, []);

  return (
    <div className="relative" ref={dropdownRef}>
      <div className={`fixed top-[1%] left-[5%] md:top-[14%] md:left-[4%] z-50`}>
        {/* Tooltip */}
        {showHighlight && (
          <div className="absolute left-full top-[170%] -translate-y-1/4 bg-custom_tertiary p-4 rounded-xl shadow-lg w-64 z-50 shadow-md">
            <p className="text-custom_white mb-2">
              Suchst du nach Programmierkursen für dich, oder für eines deiner Kinder? Hier kannst du zwischen der Eltern- und Kindansicht wechseln.
            </p>
            <button 
              onClick={dismissHighlight}
              className="text-sm font-extrabold text-custom_white hover:text-custom_neutral transition-colors duration-200 bg-custom_primary rounded-2xl py-2 px-4 shadow-md"
            >
              OK!
            </button>
          </div>
        )}

        {/* Button with conditional pulse animation */}
        <div className={`relative ${showHighlight ? 'animate-pulse' : ''}`}>
          <button
            onClick={toggleDropdown}
            className="flex items-center justify-center w-16 h-16 bg-custom_tertiary text-white rounded-full shadow-lg hover:bg-green-700 transition duration-200 focus:outline-none"
            aria-haspopup="true"
            aria-expanded={isDropdownOpen}
          >
            <img
              src={isParent ? parentIcon : kidIcon}
              alt={isParent ? "Parent" : "Child"}
              className="w-10 h-10 object-contain"
            />
          </button>
          
          {/* Ring animation when highlighting */}
          {showHighlight && (
            <div className="absolute inset-0 rounded-full animate-adapted_ping bg-custom_tertiary opacity-75"></div>
          )}
        </div>

        {/* Dropdown menu */}
        {isDropdownOpen && (
          <div className="speech-bubble absolute mt-2 w-40 rounded-md shadow-lg bg-custom_white ring-1 ring-black ring-opacity-5 left-[60%] -translate-x-3/4 sm:left-[45%] sm:-translate-x-1/2">
            <div
              className="py-1 no-padding"
              role="menu"
              aria-orientation="vertical"
              aria-labelledby="user-type-button"
            >
              <button
                onClick={() => handleUserSelect("parent")}
                className="w-full px-4 py-2 text-sm text-gray-700 hover:bg-custom_tertiary transition duration-200 text-custom_neutral hover:text-custom_white rounded-t-md"
                role="menuitem"
              >
                Elternteil
              </button>
              <button
                onClick={() => handleUserSelect("child")}
                className="w-full px-4 py-2 text-sm text-gray-700 hover:bg-custom_tertiary transition duration-200 text-custom_neutral hover:text-custom_white rounded-b-md"
                role="menuitem"
              >
                Kind
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default UserTypeButton;